@import url("../assets/font/stylesheet.css");

:root{
  --primary-color: #9B6305;
  --dark-color: #000000;
  --primary-muted-color: #a68852;
  
  --body-light-background-color: #fcfcfc;
  --body-light-sidemenu-bg-color: #F7F8F9;
  --body-light-card-color: #FFFFFF;
  
  --body-light-input-background-color: #F6F6F6;
  --placeholder-text-dark-color:  #C4CCDB;
  
  --text-font-family: 'Hellix';
  --text-mild-dark-color: #35352F;
  --text-darker-color: #333333;
  --text-dark-color: #47536C;
  --text-light-color: #ffffff;
  --text-danger-color: #f3433d;
  --form-error-bg: rgba(251, 31, 31, 0.05);

  --label-text-dark-color: #6B7B9C;
  --menu-title-text-dark-color: #b5b5be;
}

body{
  background-color: var(--body-background-color);
  font-family: var(--text-font-family);
}

p{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--text-dark-color);
}

a{
  text-decoration: none;
  color: var(--primary-color);
}

h1{
  font-weight: 600;
  font-size: 42px;
  line-height: 55px;
  color: var(--text-dark-color);
}

h3{
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: var(--text-dark-color);
}

label{
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-dark-color);
}

.error_message{
  font-size: 14px;
  color: var(--text-danger-color);
  font-weight: 500;
}
